import { MenuOutlined } from "@ant-design/icons";
import { Popover, Button, Space } from "antd";

import { useCampaignInfo } from "../../../../../../queries/insights/campaignInfo.queries";
import { useCreativeIntelligenceStore } from "../../../../../../store/creativeIntelligence.store";
import FiltersTree from "../../../FiltersTree/FiltersTree";

import ReportDatePicker from "./ReportDatePicker";

const Filters = () => {
  const campaignInfo = useCampaignInfo();
  const selectedFilters = useCreativeIntelligenceStore((state) => state.selectedFilters);
  const setSelectedFilters = useCreativeIntelligenceStore((state) => state.setSelectedFilters);

  return (
    <Space size="small">
      <Popover
        placement="bottom"
        trigger="click"
        content={
          <FiltersTree
            campaignFilters={campaignInfo.data?.campaignFilters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        }
      >
        <Button loading={campaignInfo.isPending} icon={<MenuOutlined />} data-testid="set-filters">
          Filters
        </Button>
      </Popover>

      <ReportDatePicker />
    </Space>
  );
};

export default Filters;
