import { Select, Flex, Typography } from "antd";

import { DashboardView } from "../../constants/creativeInteligence";
import { useCreativeIntelligenceStore } from "../../store/creativeIntelligence.store";
import { useCurrentDashboard } from "../../queries/entities/dashboard.query";
import PageWrapper from "../../components/DashboardView/DashboardPageWrapper";

import DataExplorer from "./components/DataExplorer/DataExplorer";
import TagList from "./components/TagList/TagList";
import DashboardFilters from "./components/DashboardFilters";

import "./DashboardPage.scss";

const { Paragraph } = Typography;

const views = {
  [DashboardView.Utilisation]: {
    title: "What my content looks like",
    description: "Discover which creative elements you're using the most",
  },
  [DashboardView.Top]: {
    title: "What's working",
    description: "Here’s your best performing creative elements",
  },
  [DashboardView.Bottom]: {
    title: "What's not working",
    description: "These creative elements are not driving performance",
  },
  [DashboardView.DataExplorer]: {
    title: "Data Explorer",
    description: "Explore all the data points in a single chart",
  },
};

const dashboardViews = Object.values(DashboardView).map((value) => ({
  label: views[value].title,
  value,
}));

const DashboardPage = () => {
  const dashboard = useCurrentDashboard();

  const dashboardView = useCreativeIntelligenceStore((state) => state.dashboardView);
  const setDashboardView = useCreativeIntelligenceStore((state) => state.setDashboardView);

  return (
    <PageWrapper>
      {!dashboard.isPending && !dashboard.data?.resourceGroupIds?.length ? (
        <div>
          <h3>This dashboard has no datasets assigned</h3>
          <h4>Please got to settings to configure datasets</h4>
        </div>
      ) : (
        <Flex vertical gap="middle">
          <section className="dashboard-content-select">
            <Select
              variant="borderless"
              value={dashboardView}
              className="dashboard-content-select-custom-selector"
              onChange={setDashboardView}
              popupMatchSelectWidth={false}
              options={dashboardViews}
            />
            <Paragraph>{views[dashboardView].description}</Paragraph>
          </section>

          <DashboardFilters />

          {dashboardView === DashboardView.DataExplorer ? <DataExplorer /> : <TagList />}
        </Flex>
      )}
    </PageWrapper>
  );
};

export default DashboardPage;
