import { useMemo } from "react";

import { MetricMode, MetricSettings } from "../types/entities";
import { useBenchmarkReportKPIs } from "../pages/DashboardPage/queries/reportKPIs.query";
import { DiscardThresholds, DiscardMetricThreshold } from "../types/creativeInteligence";
import {
  CreativeIntelligenceMetricLabel,
  CreativeIntelligenceMetrics,
  getMetricsLabel,
} from "../pages/DashboardPage/queries/utils";

import { useCurrentDashboard } from "./entities/dashboard.query";

export interface CurrentMetrics {
  kpiMetricLabel: CreativeIntelligenceMetricLabel;
  kpiMetric: MetricSettings;
  confidenceMetricLabel: CreativeIntelligenceMetricLabel;
  confidenceMetric: MetricSettings;
  discardThresholds?: DiscardThresholds;
}

function metricToDiscardThresholds(discardMetricThresholds: DiscardMetricThreshold[]): DiscardThresholds {
  return discardMetricThresholds.reduce((acc, { metric, value }) => {
    acc[metric] = value;

    return acc;
  }, {} as DiscardThresholds);
}

export function discardThresholdsToMetric(discardThresholds?: DiscardThresholds): DiscardMetricThreshold[] {
  if (!discardThresholds) {
    return [];
  }

  return Object.entries(discardThresholds).map(([metric, value]) => ({
    metric,
    value,
  }));
}

const DEFAULT_KPI_METRIC = CreativeIntelligenceMetrics.ClickRate;
const DEFAULT_CONFIDENCE_METRIC = CreativeIntelligenceMetrics.Impressions;
const DEFAULT_METRIC_MODE = MetricMode.Average;

export function useCurrentMetrics() {
  const dashboard = useCurrentDashboard();
  const reportKPIs = useBenchmarkReportKPIs();

  const data = useMemo<CurrentMetrics | undefined>(() => {
    if (!dashboard.data || !reportKPIs.data) {
      return;
    }

    const defaultKpiMetric = {
      metric: DEFAULT_KPI_METRIC,
      value: reportKPIs.data[DEFAULT_KPI_METRIC]?.avg,
      mode: DEFAULT_METRIC_MODE,
    } as MetricSettings;

    const defaultConfidenceMetric = {
      metric: DEFAULT_CONFIDENCE_METRIC,
      value: reportKPIs.data[DEFAULT_CONFIDENCE_METRIC]?.avg,
      mode: DEFAULT_METRIC_MODE,
    } as MetricSettings;

    return {
      kpiMetricLabel: getMetricsLabel(dashboard.data.kpiMetric?.metric ?? DEFAULT_KPI_METRIC),
      kpiMetric: dashboard.data?.kpiMetric ?? defaultKpiMetric,
      confidenceMetricLabel: getMetricsLabel(dashboard.data.confidenceMetric?.metric ?? DEFAULT_CONFIDENCE_METRIC),
      confidenceMetric: dashboard.data?.confidenceMetric ?? defaultConfidenceMetric,
      discardThresholds: dashboard.data.discardThresholds
        ? metricToDiscardThresholds(dashboard.data.discardThresholds)
        : undefined,
    };
  }, [dashboard.data, reportKPIs.data]);

  return {
    isPending: dashboard.isPending || reportKPIs.isPending,
    data,
  };
}

export const useKpiMetricName = () => {
  const currentMetrics = useCurrentMetrics();

  return currentMetrics.data?.kpiMetric.metric;
};
