import { useMemo } from "react";
import { Checkbox, Col, Form, InputNumber, InputNumberProps, Row, Select } from "antd";

import { DomainConfig } from "../../../store/config/types";
import { AIServices, GCVFaceDetectionLikelihood } from "../../../types/entities";
import { MainDivider } from "../JobDetails";

import { DeliveryJobFormValues } from "../AddDeliveryJob";

const AI_SERVICE_TO_LABEL = {
  [AIServices.GCV]: "Google Cloud Vision",
  [AIServices.AWS]: "AWS Rekognition",
  [AIServices.WTBG]: "Wunderman Thompson Brand Guardian",
  [AIServices.CLARIFAI]: "Clarifai",
  [AIServices.CLEANUP]: "Cleanup",
  [AIServices.DRAGONFLY]: "Dragonfly",
  [AIServices.GOOGLE_NATURAL_LANGUAGE]: "Google Natural Language",
  [AIServices.TEXTURE_AI]: "Texture.AI",
  [AIServices.GOOGLE_VIDEO_INTELLIGENCE]: "Google Video Intelligence",
  [AIServices.IMG2PROMPT]: "Img2Prompt",
} as const;

const Percentage = (props: InputNumberProps) => <InputNumber min={0} max={100} addonAfter="%" {...props} />;
const ZeroToOne = (props: InputNumberProps) => <InputNumber min={0} max={1} step={0.1} {...props} />;

const gcvFaceOptions = Object.keys(GCVFaceDetectionLikelihood).map((label) => ({ label, value: label }));

const GCVForm = ({ show }: { show: boolean }) => {
  const formName = ["enrichAIConfig", AIServices.GCV];

  return (
    <>
      {show && <MainDivider>{AI_SERVICE_TO_LABEL.GCV}</MainDivider>}

      <Form.Item
        name={[...formName, "COLOUR_SCORE_THRESHOLD"]}
        label="Color Score Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "COLOUR_PIXEL_FRACTION_THRESHOLD"]}
        label="Color Pixel Fraction Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "FACE_LIKELIHOOD_THRESHOLD"]}
        label="Face Likelihood Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Select mode="multiple" options={gcvFaceOptions} />
      </Form.Item>

      <Form.Item
        name={[...formName, "FACE_CONFIDENCE_THRESHOLD"]}
        label="Face Confidence Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "LABEL_SCORE_THRESHOLD"]}
        label="Label Score Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "LOGO_SCORE_THRESHOLD"]}
        label="Logo Score Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "OBJECTS_SCORE_THRESHOLD"]}
        label="Objects Score Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "TEXT_DETECTION_THRESHOLD"]}
        label="Text Detection Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>
    </>
  );
};

const comprehendTextAnalysisOptions = [
  { label: "keywords", value: "keywords" },
  { label: "textSentiments", value: "textSentiments" },
  { label: "entities", value: "entities" },
  { label: "keyPhrases", value: "keyPhrases" },
];

const AWSForm = ({ show }: { show: boolean }) => {
  const formName = ["enrichAIConfig", AIServices.AWS];

  return (
    <>
      {show && <MainDivider>{AI_SERVICE_TO_LABEL.AWS}</MainDivider>}

      <Form.Item
        name={[...formName, "COMPREHEND_TEXT_ANALYSIS"]}
        label="Comprehend Text Analysis"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Select options={comprehendTextAnalysisOptions} mode="tags" />
      </Form.Item>

      <Form.Item
        name={[...formName, "FACE_CONFIDENCE_THRESHOLD"]}
        label="Face Confidence Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "GENDER_CONFIDENCE_THRESHOLD"]}
        label="Gender Confidence Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "EMOTION_CONFIDENCE_THRESHOLD"]}
        label="Emotion Confidence Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "FACIAL_FEATURE_CONFIDENCE_THRESHOLD"]}
        label="Facial Feature Confidence Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "LABEL_CONFIDENCE_THRESHOLD"]}
        label="Label Confidence Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item name={[...formName, "TEXT_ANALYSIS"]} label="Text Analysis" hidden={!show}>
        <Checkbox />
      </Form.Item>

      <Form.Item name={[...formName, "CustomLabels", "MODELS_ARNS"]} label="Custom Labels (MODELS_ARNS)" hidden={!show}>
        <Select mode="tags" />
      </Form.Item>
    </>
  );
};

const CleanupForm = ({ show }: { show: boolean }) => {
  const formName = ["enrichAIConfig", AIServices.CLEANUP];

  return (
    <>
      {show && <MainDivider>{AI_SERVICE_TO_LABEL.CLEANUP}</MainDivider>}

      <Form.Item
        name={[...formName, "GENERAL_BOUNDING_BOX_AREA_THRESHOLD"]}
        label="General Bounding Box Area Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "GENERAL_AI_RESULT_OCCURRENCES_THRESHOLD"]}
        label="General AI Result Occurrences Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name={[...formName, "TEXT_BOUNDING_BOX_INTERSECTION_THRESHOLD"]}
        label="Text Bounding Box Intersection Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>

      <Form.Item
        name={[...formName, "TEXT_SIMILARITY_THRESHOLD"]}
        label="Text Similarity Threshold"
        rules={[{ required: true }]}
        hidden={!show}
      >
        <Percentage />
      </Form.Item>
    </>
  );
};

const GVIForm = ({ show }: { show: boolean }) => {
  const formName = ["enrichAIConfig", AIServices.GOOGLE_VIDEO_INTELLIGENCE];

  return (
    <>
      {show && <MainDivider>{AI_SERVICE_TO_LABEL.GOOGLE_VIDEO_INTELLIGENCE}</MainDivider>}

      <Form.Item name={[...formName, "FACE_ATTRIBUTES_THRESHOLD"]} label="Face Attributes Threshold" hidden={!show}>
        <ZeroToOne />
      </Form.Item>

      <Form.Item name={[...formName, "FACE_DETECTION_THRESHOLD"]} label="Face Detection Threshold" hidden={!show}>
        <ZeroToOne />
      </Form.Item>

      <Form.Item name={[...formName, "TEXT_DETECTION_THRESHOLD"]} label="Text Detection Threshold" hidden={!show}>
        <ZeroToOne />
      </Form.Item>

      <Form.Item name={[...formName, "LOGO_DETECTION_THRESHOLD"]} label="Logo Detection Threshold" hidden={!show}>
        <ZeroToOne />
      </Form.Item>

      <Form.Item name={[...formName, "PERSON_DETECTION_THRESHOLD"]} label="Person Detection Threshold" hidden={!show}>
        <ZeroToOne />
      </Form.Item>

      <Form.Item name={[...formName, "OBJECT_TRACKING_THRESHOLD"]} label="Object Tracking Threshold" hidden={!show}>
        <ZeroToOne />
      </Form.Item>

      <Form.Item name={[...formName, "LABEL_DETECTION_THRESHOLD"]} label="Label Detection Threshold" hidden={!show}>
        <ZeroToOne />
      </Form.Item>
    </>
  );
};

const EnrichForm = ({ config, show }: { config: DomainConfig; show: boolean }) => {
  const form = Form.useFormInstance();
  const enrichServices = Form.useWatch<DeliveryJobFormValues["enrichServices"]>("enrichServices", form);

  const enrichOptions = useMemo(() => {
    return Object.keys(config.enrichAIConfig).map((label) => ({
      label: AI_SERVICE_TO_LABEL[label as keyof typeof AI_SERVICE_TO_LABEL],
      value: label,
      disabled: label === AIServices.AWS || label === AIServices.GCV,
    }));
  }, [config.enrichAIConfig]);

  return (
    <>
      <Form.Item name="enrichServices" label="Enrich Services" hidden={!show}>
        <Checkbox.Group>
          <Row>
            {enrichOptions?.map((option) => {
              return (
                <Col key={option.label} span={24}>
                  <Checkbox value={option.value} disabled={option.disabled}>
                    {option.label}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <GCVForm show={show && enrichServices?.includes(AIServices.GCV)} />
      <AWSForm show={show && enrichServices?.includes(AIServices.AWS)} />
      <CleanupForm show={show && enrichServices?.includes(AIServices.CLEANUP)} />
      <GVIForm show={show && enrichServices?.includes(AIServices.GOOGLE_VIDEO_INTELLIGENCE)} />
    </>
  );
};

export default EnrichForm;
