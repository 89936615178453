import { useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Modal, Form, Typography, Select, Button, Alert, FormInstance, Flex, FlexProps, Spin } from "antd";

import { ReportKpis, useBenchmarkReportKPIs } from "../../../../../../pages/DashboardPage/queries/reportKPIs.query";
import { useUpdateDashboard } from "../../../../../../queries/entities/dashboard.mutations";
import { useCurrentDashboard } from "../../../../../../queries/entities/dashboard.query";
import {
  CurrentMetrics,
  discardThresholdsToMetric,
  useCurrentMetrics,
} from "../../../../../../queries/metrics.queries";
import {
  DiscardMetricThreshold,
  DiscardThresholds as DiscardThresholdsType,
} from "../../../../../../types/creativeInteligence";
import { MetricSettings, MetricMode } from "../../../../../../types/entities";

import DiscardThresholds from "./DiscardThresholds";
import MetricModeValueField from "./MetricModeValueField";

import "./Benchmarks.scss";

export const FormRow = (props: FlexProps) => <Flex style={{ marginBottom: "16px" }} {...props} />;

const { Title, Text } = Typography;

export interface FormValues {
  kpiMetric: MetricSettings | undefined;
  confidenceMetric: MetricSettings | undefined;
  discardThresholds: DiscardMetricThreshold[] | undefined;
}

interface FieldData {
  name: string[];
  value?: "string" | "number";
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

const BenchmarksForm = ({
  form,
  reportKPIs,
  currentMetrics,
  onFinish,
}: {
  form: FormInstance<FormValues>;
  reportKPIs: ReportKpis | undefined;
  currentMetrics: CurrentMetrics | undefined;
  onFinish: (values: FormValues) => Promise<void>;
}) => {
  const noData = !reportKPIs || !currentMetrics;

  const reportKpisOptions = useMemo(
    () =>
      Object.entries(reportKPIs ?? {}).map(([key, value]) => ({
        value: key,
        label: value.label,
      })),
    [reportKPIs]
  );

  const initialFormValues = useMemo(() => {
    if (!currentMetrics) {
      return;
    }

    return {
      kpiMetric: currentMetrics.kpiMetric,
      confidenceMetric: currentMetrics.confidenceMetric,
      discardThresholds: discardThresholdsToMetric(currentMetrics.discardThresholds),
    };
  }, [currentMetrics]);

  const handleFieldsChange = useCallback(
    (changedFields: FieldData[]) => {
      if (!reportKPIs || !currentMetrics) {
        return;
      }

      changedFields.forEach((field) => {
        /**
         * if the metric type or mode changes, we need to update its value;
         * if the metric is the current one from the dashboard we use that,
         * otherwise we use the avg from the reportKPIs
         */
        if (field.name[1] === "metric" || field.name[1] === "mode") {
          const metricType = field.name[0] as "kpiMetric | confidenceMetric";
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const metricSettings = form.getFieldsValue([metricType])[metricType] as MetricSettings;

          const current = currentMetrics[metricType as keyof CurrentMetrics] as MetricSettings;

          if (metricSettings.mode === MetricMode.Value && current.metric === metricSettings.metric) {
            form.setFieldValue([metricType, "value"], current.value);
          } else {
            form.setFieldValue([metricType, "value"], reportKPIs[metricSettings.metric]?.avg);
          }
        }
      });
    },
    [currentMetrics, form, reportKPIs]
  );

  if (noData) {
    return (
      <Flex align="center" justify="center">
        <Spin />
      </Flex>
    );
  }

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialFormValues}
      onFinish={onFinish}
      disabled={noData}
      onFieldsChange={handleFieldsChange}
    >
      <FormRow gap="middle" justify="space-between">
        <Text strong>KPI Metric</Text>

        <Form.Item name={["kpiMetric", "metric"]} noStyle>
          <Select popupMatchSelectWidth={false} data-testid="kpi-metric-select" options={reportKpisOptions} />
        </Form.Item>
      </FormRow>

      <FormRow>
        <MetricModeValueField name="kpiMetric" dataTestId="kpi-metric-mode-select" />
      </FormRow>

      <FormRow>
        <Text>
          Select which KPI is most important to you, and provide a benchmark where an Ad that has performed above this
          number is considered“good”.
        </Text>
      </FormRow>

      <FormRow gap="middle" justify="space-between">
        <Text strong>Confidence Metric</Text>
        <Form.Item name={["confidenceMetric", "metric"]} noStyle>
          <Select popupMatchSelectWidth={false} data-testid="confidence-metric-select" options={reportKpisOptions} />
        </Form.Item>
      </FormRow>

      <FormRow>
        <MetricModeValueField name="confidenceMetric" dataTestId="confidence-metric-mode-select" />
      </FormRow>

      <FormRow>
        <Text>
          Select a metric which represents statistical significance, and provide a threshold where an Ad that has
          reached beyond this number is considered “well tested”.
        </Text>
      </FormRow>

      <DiscardThresholds reportKPIs={reportKPIs} />
    </Form>
  );
};

const Benchmarks = () => {
  const { brandId } = useParams();
  const dashboard = useCurrentDashboard();
  const currentMetrics = useCurrentMetrics();
  const reportKPIs = useBenchmarkReportKPIs();
  const updateDashboard = useUpdateDashboard();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = useCallback(() => setIsModalVisible(true), []);
  const hideModal = useCallback(() => setIsModalVisible(false), []);

  const [form] = Form.useForm<FormValues>();

  const handleFormSubmit = useCallback(() => form.submit(), [form]);

  const handleSaveBenchmarkMetrics = useCallback(
    async (values: FormValues) => {
      if (!dashboard.data) {
        console.error("No dashboard data");

        return;
      }

      await updateDashboard.mutateAsync({
        brandId,
        dashboardId: dashboard.data.id,
        dashboardData: {
          kpiMetric: values.kpiMetric,
          confidenceMetric: values.confidenceMetric,
          ...(values.discardThresholds && {
            discardThresholds: discardThresholdsToMetric(
              values.discardThresholds.reduce<DiscardThresholdsType>((acc, discardThreshold) => {
                acc[discardThreshold.metric] = discardThreshold.value;

                return acc;
              }, {})
            ),
          }),
        },
      });

      hideModal();
    },
    [brandId, dashboard.data, updateDashboard, hideModal]
  );

  const handleCancel = useCallback(() => {
    form.resetFields();
    hideModal();
  }, [form, hideModal]);

  const noData = currentMetrics.data?.kpiMetric.value === null || currentMetrics.data?.confidenceMetric.value === null;

  return (
    <>
      <Button className="custom-benchmark-btn" onClick={showModal} data-testid="set-benchmarks">
        <Text className="custom-benchmar-btn-label">Benchmark</Text>
        <Text>{currentMetrics.data?.kpiMetricLabel ?? "..."}</Text>
      </Button>

      <Modal
        title={<Title level={4}>Set Benchmarks & Confidence Thresholds</Title>}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel} data-testid="cancel-button">
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleFormSubmit}
            loading={updateDashboard.isPending}
            disabled={noData}
          >
            Save
          </Button>,
        ]}
      >
        <BenchmarksForm
          form={form}
          currentMetrics={currentMetrics.data}
          reportKPIs={reportKPIs.data}
          onFinish={handleSaveBenchmarkMetrics}
        />

        {updateDashboard.error && <Alert message="Failed to save benchmark options" type="error" />}
      </Modal>
    </>
  );
};

export default Benchmarks;
